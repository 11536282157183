import "core-js/stable";
import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore} from "redux";
import ReactGA from 'react-ga';
import App from './components/App';
import reducers from './redux/reducers';
import getEnvironment from "./utilities/getEnvironment";
import isIPhone from "./utilities/isIPhone";

ReactGA.initialize(getEnvironment() === 'production' ? process.env.REACT_APP_GA_ID_PRODUCTION : process.env.REACT_APP_GA_ID_IJSPREVIEW, { debug: process.env.NODE_ENV === 'development' });
ReactGA.set({ anonymizeIp: true });
const store = createStore(
    reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
    <div
      style={isIPhone() ? {minHeight: '110vh'} : null}
    >
      <Provider store={store}>
          <App/>
      </Provider>
    </div>,
    document.querySelector('#root')
);