import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';

import styles from './hint.module.scss';

class Hint extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    pointerLocation: PropTypes.oneOf(['topLeft', 'topRight', 'bottomRight', 'bottomLeft']),
    pulse: PropTypes.bool,
    classNames: PropTypes.string
  };

  static defaultProps = {
    pulse: true
  };

  render() {
    const { children, pointerLocation, pulse, classNames } = this.props;

    const hintClassNames = cn(styles.hint, {
      [styles.pulse]: pulse,
      [styles.topLeft]: pointerLocation === 'topLeft',
      [styles.topRight]: pointerLocation === 'topRight',
      [styles.bottomRight]: pointerLocation === 'bottomRight',
      [styles.bottomLeft]: pointerLocation === 'bottomLeft',
    }, classNames);
    return (
      <div className={hintClassNames}>
        {children}
      </div>
    );
  }
}

export default Hint;