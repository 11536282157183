import React from 'react';
import {connect} from "react-redux";

import styles from './Living.module.scss';
import ImageMapper from "react-image-mapper";

import {
    addToScore,
    setCurrentPage,
    setLivingPageSeen,
    unlockObjective
} from "../../../redux/actions";
import {
    getCurrentPage,
    getObjectivesByCategory,
    getScore,
    getUnlockedObjectivesByCategory
} from "../../../redux/selectors";
import ObjectiveDialog from "../photoObjective/ObjectiveDialog";
import full from "../../../assets/rooms/living_textured_72dpi.jpg";
import cn from "classnames";
import Hint from "../../hint/hint";
import HouseHeader from "./houseHeader";
import RoomNavButtons from "./roomNavButtons/roomNavButtons";
import RoomPhotoTips from "./roomPhotoTips/roomPhotoTips";

const mapFull = {
    name: "back",
    areas: [{
        name: "woonkamer_telefoon",
        shape: "circle",
        coords: [1249, 598, 48],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "woonkamer_stoel",
        shape: "poly",
        coords: [1285, 507, 1314, 527, 1398, 537, 1426, 522, 1448, 541, 1415, 682, 1385, 712, 1351, 731, 1220, 700, 1205, 660, 1231, 651, 1278, 648, 1302, 631, 1307, 575, 1287, 563],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "woonkamer_rookmelders",
        shape: "circle",
        coords: [206, 712, 40],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "woonkamer_laptop",
        shape: "rect",
        coords: [1615, 734, 1785, 661],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "woonkamer_deur",
        shape: "poly",
        coords: [272, 185, 408, 219, 411, 729, 271, 785],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "woonkamer_kaars",
        shape: "rect",
        coords: [1037, 710, 1088, 600],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "woonkamer_tafel",
        shape: "poly",
        coords: [835, 663, 1110, 682, 1146, 738, 1086, 744, 1046, 807, 803, 780, 751, 704],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }]
}

class Living extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props,
            screenWidth: 0,
            screenHeight: 0,
            hoveredArea: null,
            openDialog: false,
            objective: null,
            flash: false,
            hidePhotoTips: true
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.timerID = setInterval(
            () => this.togglePhotoTips(),
            120000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    togglePhotoTips() {
        this.setState({hidePhotoTips: !this.state.hidePhotoTips})
    }

    componentWillMount() {
        this.props.setCurrentPage("living");
        this.props.setLivingPageSeen();
        window.removeEventListener('resize', this.updateWindowDimensions);
    };

    updateWindowDimensions() {
        this.setState({...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight});
    }

    enterArea = (area) => {
        this.setState({
            ...this.state,
            hoveredArea: area,
        });
    }

    leaveArea = (area) => {
        this.setState({
            ...this.state,
            hoveredArea: null,
        });
    }

    handleOnClick = (area) => {
        this.setState({
            ...this.state,
            objective: this.props.objectives.find(o => o.id === area.name),
            hidePhotoTips: true
        })
        this.props.unlockObjective(area.name, false);
        this.props.addToScore("totalScore", 1);
        this.toggleFlash();
        this.toggleDialog();
    }

    toggleDialog = () => {
        this.toggleFlash();
        this.setState({openDialog: !this.state.openDialog});
    };
    toggleFlash = () => {
        this.setState({flash: !this.state.flash});
    };

    render() {
        const totalPhotoCount = this.props.objectives.length;
        const totalUnlockedPhotoCount = this.props.unlockedObjectives.length;
        const roomObjectsFound = this.props.unlockedObjectives.filter(x => x.id.includes('woonkamer')).length;
        const totalRoomObjects = this.props.objectives.filter(x => x.id.includes('woonkamer')).length;
        return (
            <div className={styles.living}>
                <HouseHeader
                    closeBtn={!this.state.openDialog}
                    title={this.props.currentPage.name}
                    description={`${roomObjectsFound} van de ${totalRoomObjects} objecten gevonden`}
                />
                <div className={cn(styles.flash, this.state.flash && styles.active)}/>
                <div className={styles.imageMapContainer}>
                    <ImageMapper
                        src={full}
                        map={mapFull}
                        width={this.state.screenWidth - 40}
                        imgWidth={1920}
                        lineWidth={0}
                        strokeColor={"rgba(255, 255, 255, 0)"}
                        onClick={area => this.handleOnClick(area)}
                        onMouseEnter={area => this.enterArea(area)}
                        onMouseLeave={area => this.leaveArea(area)}
                    />
                    <RoomPhotoTips map={mapFull} room='woonkamer' screenWidth={this.state.screenWidth}
                                   hidden={this.state.hidePhotoTips}/>
                </div>
                {roomObjectsFound === 0 && (
                    <div className={styles.hintContainer}>
                        <Hint pointerLocation="topRight">
                            <h3>Zoek en klik op de juiste voorwerpen om een foto te maken</h3>
                        </Hint>
                    </div>
                )}
                {roomObjectsFound === totalRoomObjects && totalPhotoCount !== totalUnlockedPhotoCount &&
                <div className={styles.hintContainerRight}/>}
                {totalPhotoCount === totalUnlockedPhotoCount &&
                <div className={styles.hintContainerConclusion}/>}
                {this.state.objective && (
                    <ObjectiveDialog
                        objective={this.state.objective}
                        open={this.state.openDialog}
                        toggleDialog={this.toggleDialog}
                        autoPlay={true}
                    />
                )}
                <RoomNavButtons
                    leftBtnLabel="Zolder"
                    leftBtnRoute="../house/attic"
                    rightBtnLabel="Slaapkamer"
                    rightBtnRoute="../house/bedroom"
                />
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        currentPage: getCurrentPage(state),
        objectives: getObjectivesByCategory(state, 'photos'),
        unlockedObjectives: getUnlockedObjectivesByCategory(state, 'photos'),
        score: getScore(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    unlockObjective: (objectiveId, locked) => dispatch(unlockObjective(objectiveId, locked)),
    addToScore: (scoreId, amount) => dispatch(addToScore(scoreId, amount)),
    setLivingPageSeen: () => dispatch(setLivingPageSeen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Living);