import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';

import styles from './button.module.scss';

class Button extends React.PureComponent {

  static propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    classNames: PropTypes.string,
    size: PropTypes.oneOf(['default', 'large']),
    type: PropTypes.oneOf(['default', 'flat']),
    icon: PropTypes.node,
    iconLocation: PropTypes.oneOf(['left', 'right']),
    bold: PropTypes.bool,
    style: PropTypes.oneOf(['default', 'white', 'black'])
  };

  static defaultProps = {
    size: 'default',
    type: 'default',
    iconLocation: 'right',
    bold: false,
    style: 'default'
  };

  render() {
    const { label, children, onClick, classNames, size, type, icon, iconLocation, bold, style } = this.props;

    const buttonClassNames = cn(styles.button, {
      [styles.large]: size === 'large',
      [styles.flat]: type === 'flat',
      [styles.bold]: bold,
      [styles.white]: style === 'white',
      [styles.black]: style === 'black',
      [styles.hasIcon]: !!icon
    }, classNames);
    return (
      <button className={buttonClassNames} onClick={onClick}>
        {icon && iconLocation === 'left' && <span className={styles.icon}>{icon}</span>}
        {children || label}
        {icon && iconLocation === 'right' && <span className={styles.icon}>{icon}</span>}
      </button>
    );
  }
}

export default Button;