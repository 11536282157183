import React from "react";
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";

import styles from './Intro.module.scss';

import {setAudioAutoPlay, setCurrentPage} from "../../redux/actions";
import {getAudioSettingsById} from "../../redux/selectors";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import audioIntro from '../../assets/audio/recordings/Welkom_v01.mp3'
import TalkingCat from "./TalkingCat";
import Button from "../button";

class Intro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: this.props.audio.autoPlay,
            buttonVisible: false
        };

        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);
    }

    handlePlay() {
        this.setState({
            playing: true
        });
    }

    handlePause() {
        this.setState({
            playing: false
        });
    }

    componentDidMount() {
        this.props.setCurrentPage("intro");
    };

    handleOnEnd = () => {
        this.setState({
            playing: false,
            buttonVisible: true
        });
        this.props.setAudioAutoPlay("intro", false)
    };

    navigateTo = (location) => () => {
        this.props.history.push(location);
    };

    render() {
        return (
          <div className={styles.intro}>
              <div className={styles.house}>
                  <div className={styles.talkingCat}>
                      <TalkingCat
                        audioSource={audioIntro}
                        playing={this.state.playing}
                        balloonColor={'blue'}
                        handleOnEnd={this.handleOnEnd}
                        handlePlay={this.handlePlay}
                        handlePause={this.handlePause}
                        catType={'cable'}
                      />
                  </div>
              </div>
              <div className={styles.footer}>
                  <h2>Hé speurneus, goed dat je er bent!</h2>
                  {this.state.buttonVisible ? (
                    <div className={styles.playBtnContainer}>
                        <Button
                          label="Start"
                          onClick={this.navigateTo("./bystanders")}
                          size="large"
                        />
                    </div>
                  ) : (
                    <div className={styles.skipBtnContainer}>
                        <Button
                          label="Intro overslaan"
                          onClick={this.navigateTo("./bystanders")}
                          type="flat"
                          icon={<ArrowForwardIosIcon/>}
                        />
                    </div>
                  )}
              </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => ({
    audio: getAudioSettingsById(state, "intro")
});

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    setAudioAutoPlay: (id, autoPlay) => dispatch(setAudioAutoPlay(id, autoPlay)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Intro));
