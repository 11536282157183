import React from 'react';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";

import styles from './Bystanders.module.scss';

import {setCurrentPage} from "../../redux/actions";
import {getBystanders} from "../../redux/selectors";

import Person from "./person";

class Bystanders extends React.Component {
    componentWillMount() {
        this.props.setCurrentPage("bystanders");
    };

    render() {
        return (
            <div className={styles.bystanders}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    {this.props.bystanders.map((bystander, i) => (
                        <Grid item key={i}>
                            <Person person={bystander}/>
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        bystanders: getBystanders(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bystanders);