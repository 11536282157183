import React from 'react';
import cn from 'classnames';
import imgFloortje from "../../assets/interface/Home_Floortje.svg";
import imgFloortjeCable from "../../assets/interface/Poes_ANIMATIE_FLOORTJE_GIF_DEF_DEF-02_blauw.gif";
import imgFloortjeTriangle from "../../assets/interface/Poes_ANIMATIE_FLOORTJE_GIF_DEF_DEF-02_driehoek.gif";
import styles from './TalkingCat.module.scss';
import imgPausePurple from "../../assets/interface/spreekballon paars pauze.svg";
import imgPlayPurple from "../../assets/interface/spreekballon paars play.svg";
import imgPauseOrange from "../../assets/interface/spreekballon oranje pauze.svg";
import imgPlayOrange from "../../assets/interface/spreekballon oranje play.svg";
import imgPauseBlue from "../../assets/interface/spreekballonnen_blauw-72.svg";
import imgPlayBlue from "../../assets/interface/spreekballonnen_blauw-71.svg";
import ReactHowler from "react-howler";
import PropTypes from "prop-types";
import AudioPubSub from "../../utilities/audio/audioPubsub";
import {AUDIO_PUB_SUB_PAUSE, AUDIO_PUB_SUB_SPEECH} from "../../utilities/audio/constants";

class TalkingCat extends React.Component {
  static propTypes = {
    audioSource: PropTypes.string,
    balloonColor: PropTypes.string,
    catType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.audioPubSub = AudioPubSub.getInstance();
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);

    if (this.props.playing === true) {
      this.handlePlay();
    }
  }

  handlePlay() {
    this.audioPubSub.publish(AUDIO_PUB_SUB_SPEECH, null, AUDIO_PUB_SUB_PAUSE);
    this.props.handlePlay();
  }

  handlePause() {
    this.props.handlePause();
  }

  handleOnEnd() {
    this.props.handleOnEnd();
  }

  componentDidMount() {
    this.audioPubSub.subscribe(AUDIO_PUB_SUB_SPEECH, this.audioPubSubHandler);
  }

  componentWillUnmount() {
    this.audioPubSub.unsubscribe(AUDIO_PUB_SUB_SPEECH, this.audioPubSubHandler);
  }

  audioPubSubHandler = (context, message) => {
    switch (message) {
      case AUDIO_PUB_SUB_PAUSE:
        this.props.handlePause();
        break;
      default:
        break;
    }
  };

  getTextBalloonImage() {
    if (this.props.playing) {
      switch (this.props.balloonColor) {
        case 'purple':
          return imgPausePurple;
        case 'blue':
          return imgPauseBlue;
        default:
          return imgPauseOrange
      }
    }
    switch (this.props.balloonColor) {
      case 'purple':
        return imgPlayPurple;
      case 'blue':
        return imgPlayBlue;
      default:
        return imgPlayOrange
    }
  }

  getCatImage() {
    switch (this.props.catType) {
      case 'cable':
        return <img className={styles.cable} src={imgFloortjeCable} alt="Floortje"/>;
      case 'triangle':
        return <img className={styles.triangle} src={imgFloortjeTriangle} alt="Floortje"/>;
      default:
        return <img className={styles.default} src={imgFloortje} alt="Floortje"/>;
    }
  }

  render() {
    const textBalloonImage = this.getTextBalloonImage();
    const catImage = this.getCatImage();
    return (
      <div className={cn(styles.main, {
        [styles.mainTriangle]: this.props.catType === 'triangle'
      })}>
        <ReactHowler
          playing={this.props.playing}
          src={this.props.audioSource}
          volume={1}
          onEnd={this.handleOnEnd}
        />
        <img
          src={textBalloonImage}
          className={styles.textBalloon}
          alt="Pause"
          onClick={this.props.playing ? this.handlePause : this.handlePlay}
        />
        {catImage}
      </div>
    )
  }
}

export default TalkingCat;