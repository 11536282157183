import React from 'react';
import {setAudioAutoPlay, setCurrentPage} from "../../../redux/actions";
import {connect} from "react-redux";
import ImageMapper from 'react-image-mapper';

import styles from './House.module.scss';
import Houses from "../../../assets/street_unburned_72dpi.jpg"
import audioInstructionsHouse from '../../../assets/audio/recordings/Uitleg huis v_01.mp3'
import TalkingCat from "../TalkingCat";
import {
    getAtticPageSeen,
    getAudioSettingsById,
    getBedroomPageSeen,
    getLivingPageSeen,
} from "../../../redux/selectors";

const MAP = {
    name: "my-map",
    areas: [
        {
            name: "attic",
            shape: "poly",
            coords: [786, 426, 834, 317, 1081, 309, 1130, 424],
            prefillColor: "rgba(255, 255, 255, 0)",
            fillColor: "rgba(255, 255, 255, 0.1)"
        },
        {
            name: "bedroom",
            shape: "poly",
            coords: [779, 458, 1137, 459, 1132, 639, 779, 641],
            prefillColor: "rgba(255, 255, 255, 0)",
            fillColor: "rgba(255, 255, 255, 0.1)"
        },
        {
            name: "living",
            shape: "poly",
            coords: [779, 878, 779, 644, 1132, 643, 1125, 878],
            prefillColor: "rgba(255, 255, 255, 0)",
            fillColor: "rgba(255, 255, 255, 0.1)"
        },
    ]
};

class House extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            top: false,
            left: false,
            right: false,
            screenWidth: 0,
            screenHeight: 0,
            hoveredArea: null,
            playing: this.props.audio.autoPlay
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillMount() {
        this.props.setCurrentPage("house");
        window.removeEventListener('resize', this.updateWindowDimensions);
    };

    updateWindowDimensions() {
        this.setState({...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight});
    }

    handleOnClick = (area) => {
        this.props.history.push('./house/' + area.name);
    }

    enterArea = (area) => {
        this.setState({
            ...this.state,
            hoveredArea: area,
        });
    }

    leaveArea = () => {
        this.setState({
            ...this.state,
            hoveredArea: null,
        });
    }

    handlePlay() {
        this.setState({
            playing: true
        });
    }

    handlePause() {
        this.setState({
            playing: false
        });
    }

    handleOnEnd() {
        this.setState({
            playing: false,
            buttonVisible: true
        });
        this.props.setAudioAutoPlay("house", false)
    }


    render() {
        const {atticPageSeen, bedroomPageSeen, livingPageSeen} = this.props;

        return (
            <div className={styles.houses}>
                {(!atticPageSeen || !bedroomPageSeen || !livingPageSeen) && <div className={styles.hintContainer}/>}
                <ImageMapper
                    src={Houses}
                    map={MAP}
                    width={this.state.screenWidth}
                    imgWidth={1920}
                    lineWidth={0}
                    strokeColor={"rgba(255, 255, 255, 0)"}
                    onClick={area => this.handleOnClick(area)}
                    onMouseEnter={area => this.enterArea(area)}
                    onMouseLeave={area => this.leaveArea()}
                />
                <div className={styles.talkingCat}>
                    <TalkingCat
                        audioSource={audioInstructionsHouse}
                        playing={this.state.playing}
                        balloonColor={'orange'}
                        handleOnEnd={this.handleOnEnd}
                        handlePlay={this.handlePlay}
                        handlePause={this.handlePause}
                        catType={'cable'}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        audio: getAudioSettingsById(state, "house"),
        atticPageSeen: getAtticPageSeen(state),
        bedroomPageSeen: getBedroomPageSeen(state),
        livingPageSeen: getLivingPageSeen(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    setAudioAutoPlay: (id, autoPlay) => dispatch(setAudioAutoPlay(id, autoPlay)),
});

export default connect(mapStateToProps, mapDispatchToProps)(House);
