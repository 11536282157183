import {combineReducers} from "redux";

import settings from "./settings";
import page from "./page";
import bystanders from "./bystanders";
import residents from "./residents";
import objectives from "./objectives";
import score from "./score";

const appReducer = combineReducers({
    settings,
    page,
    bystanders,
    residents,
    objectives,
    score
});

export default (state, action) => {
    return appReducer(state, action);
};
