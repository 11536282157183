import React from 'react';
import PropTypes from 'prop-types';

import styles from './roomPhotoTips.module.scss';
import {
    getObjectivesByCategory,
} from "../../../../redux/selectors";
import {connect} from "react-redux";

import cn from 'classnames';

class RoomPhotoTips extends React.PureComponent {
    static propTypes = {
        map: PropTypes.object.isRequired,
        room: PropTypes.string.isRequired,
        screenWidth: PropTypes.number.isRequired,
    };

    getTipPosition = (area) => {
        return {top: `${this.computeCenter(area)[1] - 50}px`, left: `${this.computeCenter(area)[0]}px`};
    }

    scaleCoords(imgWidth, width, coords) {

        // calculate scale based on current 'width' and the original 'imgWidth'
        var scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
        return coords.map(function (coord) {
            return coord * scale;
        });
    }

    computeCenter(area) {
        if (!area) return [0, 0];

        var scaledCoords = this.scaleCoords(1920, this.props.screenWidth - 40, area.coords);

        switch (area.shape) {
            case "circle":
                return [scaledCoords[0], scaledCoords[1]];
            case "poly":
            case "rect":
            default: {
                var _ret = (function () {
                    // Calculate centroid
                    var n = scaledCoords.length / 2;

                    var _scaledCoords$reduce = scaledCoords.reduce(function (_ref, val, idx) {
                        var y = _ref.y;
                        var x = _ref.x;

                        return !(idx % 2) ? {y: y, x: x + val / n} : {y: y + val / n, x: x};
                    }, {y: 0, x: 0});

                    var y = _scaledCoords$reduce.y;
                    var x = _scaledCoords$reduce.x;

                    return {
                        v: [x, y]
                    };
                })();

                if (typeof _ret === "object") return _ret.v;
            }
        }
    }

    shuffleArray = (array) => {
        let i = array.length - 1;
        for (; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    }

    render() {
        const {areas} = this.props.map;
        const hidden = this.props.hidden;
        const classNames = cn(styles.tooltip, hidden ? styles.hide : '');
        const res = areas.filter(area => this.props.objectives.find(objective => objective.id === area.name));
        return (
            <div>
                {this.shuffleArray(res).slice(0, 1).map((objective, i) => (
                    <span key={i} className={classNames} style={this.getTipPosition(objective)}>
                        <p>Heb je hier al gekeken?</p>
                    </span>
                ))}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        objectives: getObjectivesByCategory(state, 'photos').filter(objective => objective.locked === true).filter(x => x.id.includes(ownProps.room)),
    };
};

export default connect(mapStateToProps)(RoomPhotoTips);