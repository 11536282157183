import React from "react";
import styles from './Title.module.scss';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom'
import {setCurrentPage, setTitlePageSeen} from "../redux/actions";

import floortje from "../assets/interface/Home_Floortje.svg";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import lectureInfo from "../assets/Lesbrief_brandwondenstichting_DEF.pdf"
import Button from "./button";
import Hint from "./hint";
import isMobileBrowser from "../utilities/isMobileBrowser";
import RenderInBrowser from 'react-render-in-browser';

class Title extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 1);
        this.props.setCurrentPage("title");
        this.props.setTitlePageSeen();
    }

    openLectureInfo = () => {
        window.open(lectureInfo);
    };

    navigateTo = (location) => () => {
        if (isMobileBrowser()) {
            this.launchIntoFullscreen(document.documentElement);
        }
        this.props.history.push(location);
    };

    launchIntoFullscreen(element) {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    }


    render() {
        return (
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <div className={styles.header}/>
                    <>
                        <img className={styles.floortje} src={floortje} alt="Floortje"/>
                        <RenderInBrowser ie only>
                            <div className={styles.browserHintContainer}>
                                <Hint pointerLocation="topLeft">
                                    <h3>We zien dat je Internet Explorer gebruikt, voor IE 11 en Edge is dit geen probleem.</h3>
                                    <h3>Gebruik je echter IE 10, dan werkt de game niet.</h3>
                                    <h3>&nbsp;</h3>
                                    <h3>We adviseren de game te openen in Chrome, Firefox, Safari, Edge of IE11.</h3>
                                </Hint>
                            </div>
                        </RenderInBrowser>
                        <div className={styles.hintContainer}>
                            <Hint pointerLocation="topLeft">
                                <h2>Psst!</h2>
                                <VolumeUpIcon/>
                                <h3>Staat je geluid aan?</h3>
                            </Hint>
                        </div>
                        <div className={styles.lectureInfoBtnContainer}>
                            <Button
                                label="Lesbrief"
                                onClick={this.openLectureInfo}
                                classNames={styles.lectureInfoBtn}
                            />
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.btnContainer}>
                                <Button
                                    onClick={this.navigateTo("/school/game/intro")}
                                    label="Ik speel klassikaal"
                                />
                            </div>
                            <div className={styles.btnContainer}>
                                <Button
                                    onClick={this.navigateTo("/home/game/intro")}
                                    label="Ik speel alleen"
                                />
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    setTitlePageSeen: () => dispatch(setTitlePageSeen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Title));