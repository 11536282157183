import json from "../initialState.js";

export default (state = json.score, action) => {
    switch (action.type) {
        case 'ADD_TO_SCORE':
            return {...state, [action.payload.scoreId]: state[action.payload.scoreId] + action.payload.amount};
        case'SUB_FROM_SCORE':
            return {...state, [action.payload.scoreId]: state[action.payload.scoreId] - action.payload.amount};
        case 'SET_SCORE_ITEM':
            return {...state, [action.payload.scoreId]: action.payload.value};
        default:
            return state;
    }
};
