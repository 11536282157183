export const getCurrentPage = store => store.page.availablePages.find(availablePage => availablePage.id === store.page.currentPage);
export const getTitlePageSeen = store => store.page.titlePageSeen;
export const getAtticPageSeen = store => store.page.atticPageSeen;
export const getBedroomPageSeen = store => store.page.bedroomPageSeen;
export const getLivingPageSeen = store => store.page.livingPageSeen;
export const getNextPage = (store, currentPage) => store.page.availablePages.find(availablePage => availablePage.id === currentPage);
export const getPreviousPage = (store, currentPage) => store.page.availablePages.find(availablePage => availablePage.id === currentPage);
export const getLocation = store => store.settings.location;
export const getBystanders = store => store.bystanders;
export const getResidents = store => store.residents;
export const getScore = store => store.score;
export const getObjectives = store => store.objectives;
export const getObjectivesByCategory = (store, category) => getObjectives(store).filter(objective => objective.category === category);
export const getObjectivesByCategoryOrderedByUnlockTime = (store, category) =>
    getObjectivesByCategory(store, category)
        .map(objective => {
            if (!objective.unlockTime) {
                return Object.assign(objective, {unlockTime: null})
            }
            return objective;
        })
        .sort((a, b) => b.unlockTime - a.unlockTime);
export const getUnlockedObjectivesByCategory = (store, category) => getObjectivesByCategory(store, category).filter(objective => objective.locked === false);
export const getCorrectObjectivesByCategory = (store, category) => getUnlockedObjectivesByCategory(store, category).filter(objective => objective.isCorrect === true);
export const getAudioSettings = store => store.settings.audio;
export const getAudioSettingsById = (store, id) => getAudioSettings(store).find(audioFile => audioFile.id === id);
