import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import styles from './houseHeader.module.scss';

class HouseHeader extends React.Component {
  static propTypes = {
    closeBtn: PropTypes.bool
  };

  render() {
    const { title, description, closeBtn } = this.props;
    return (
      <div className={styles.header}>
        <div className={styles.center}>
          <h1>{title}</h1>
          <h3 className={styles.hintBG}>{description}</h3>
        </div>
        {closeBtn && (
          <Link to="../house" className={styles.closeBtn}/>
        )}
      </div>
    );
  };
}

export default HouseHeader;