import json from "../initialState.js";

const settings = (state = json.settings, action) => {
    switch (action.type) {
        case 'SET_LOCATION':
            return {...state, location: action.payload};
        case 'SET_AUTO_PLAY':
            const index = state.audio.findIndex(file => file.id === action.payload.id);
            state.audio[index] = Object.assign({}, state.audio[index], {autoPlay: action.payload.autoPlay});
            return {...state};

        default:
            return state;
    }
};

export default settings;
