import React from 'react';
import PropTypes from 'prop-types';
import ReactHowler from "react-howler";
import styles from './Person.module.scss';

import AudioPubSub from '../../../utilities/audio/audioPubsub';

import {addToScore, unlockObjective} from "../../../redux/actions";
import {connect} from "react-redux";
import {AUDIO_PUB_SUB_PAUSE, AUDIO_PUB_SUB_SPEECH} from "../../../utilities/audio/constants";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";


class Person extends React.Component {
    static propTypes = {
        person: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            playing: false
        };

        this.audioPubSub = AudioPubSub.getInstance();
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);
    }

    componentDidMount() {
        this.audioPubSub.subscribe(AUDIO_PUB_SUB_SPEECH, this.audioPubSubHandler);
    }

    componentWillUnmount() {
        this.audioPubSub.unsubscribe(AUDIO_PUB_SUB_SPEECH, this.audioPubSubHandler);
    }

    audioPubSubHandler = (context, message) => {
        switch (message) {
            case AUDIO_PUB_SUB_PAUSE:
                this.setState({playing: false});
                break;
            default:
                break;
        }
    };

    handlePlay() {
        this.audioPubSub.publish(AUDIO_PUB_SUB_SPEECH, null, AUDIO_PUB_SUB_PAUSE);
        this.setState({
            playing: true
        });
        this.unlockItems()
    }

    handlePause() {
        this.setState({
            playing: false
        });
    }

    handleOnEnd() {
        this.setState({
            playing: false
        });
    }

    unlockItems() {
        this.props.person.linkedObjectives.forEach((objective) => {
            this.props.unlockObjective(objective, false);
            this.props.addToScore("totalScore", 1);
        });
    }

    render() {
        return (
          <div className={styles.person} onClick={this.state.playing ? this.handlePause : this.handlePlay}>
              <ReactHowler
                src={[this.props.person.audioFile]}
                playing={this.state.playing}
                volume={1}
                onEnd={this.handleOnEnd}
              />
              <img src={this.props.person.image} className={styles.image} alt={this.props.person.name}/>
              <div className={styles.row}>
                  <div className={styles.playBtnContainer}>
                      <h1>
                          {this.state.playing ? <PauseIcon fontSize={'inherit'}/> :
                            <PlayArrowIcon fontSize={'inherit'}/>}
                      </h1>
                  </div>
                  <div className={styles.text}>
                      <h2>{this.props.person.name}</h2>
                      <h3>{this.props.person.description}</h3>
                  </div>
              </div>
          </div>
        );
    };
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) => ({
    unlockObjective: (objectiveId, locked) => dispatch(unlockObjective(objectiveId, locked)),
    addToScore: (scoreId, amount) => dispatch(addToScore(scoreId, amount))
});

export default connect(mapStateToProps, mapDispatchToProps)(Person);