import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";

import Title from "./Title";
import Game from "./game";
import EscapeRoute from "./info/EscapeRoute";

import "../style/main.scss"
import TurnPage from "./TurnPage";
import withTracker from "./tracking/withTracking";
import NoMatch from "../pages/noMatch";

const customHistory = createBrowserHistory();

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            screenOrientation: this.getScreenOrientation()
        };
    }

    componentDidMount() {
        this.setScreenOrientation();
        window.addEventListener('orientationchange', this.setScreenOrientation);
        window.addEventListener("resize", this.setScreenOrientation);
    }

    isPortraitMode = () => {
        const {screenOrientation} = this.state;
        return screenOrientation === 'portrait';
    };

    getScreenOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches) {
            return 'portrait'
        } else if (window.matchMedia("(orientation: landscape)").matches) {
            return 'landscape';
        }
    };

    setScreenOrientation = () => {
        if (this.getScreenOrientation() === 'portrait' && this.state.screenOrientation !== 'portrait') {
            this.setState({
                screenOrientation: 'portrait'
            });
        } else if (this.getScreenOrientation() === 'landscape' && this.state.screenOrientation !== 'landscape') {
            this.setState({
                screenOrientation: 'landscape'
            });
        }
    };

    render() {
        if (this.isPortraitMode()) {
            return <TurnPage/>;
        }

        return (
          <BrowserRouter history={customHistory}>
              <Switch>
                  <Route path="/" exact component={withTracker(Title)}/>
                  <Route path="/*/game" component={Game}/>
                  <Route path="/vluchtplan" exact component={withTracker(EscapeRoute)}/>
                  <Route component={NoMatch}/>
              </Switch>
          </BrowserRouter>
        )
    }
}

export default App;