import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames';

import styles from "./TimelineObjective.module.scss";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

class TimelineObjective extends React.Component {
    static propTypes = {
        objective: PropTypes.object.isRequired
    };

    render() {
        const {objective} = this.props;

        const timelineObjectiveClassNames = cn(styles.timelineObjective, {
            [styles.locked]: objective.locked,
            [styles[objective.person]]: objective.person
        });

        return (
          <Card classes={{root: timelineObjectiveClassNames}} square={true} elevation={0}>
              <div className={styles.userImage} />
              <CardContent classes={{root: styles.timelineObjectiveContent}}>
                  <p>{objective.locked ? 'Vergrendeld' : objective.name}</p>
              </CardContent>
          </Card>
        );
    }
}

export default TimelineObjective;
