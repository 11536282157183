import React from 'react';

import styles from './TurnPage.module.scss';
import RotateRightIcon from "@material-ui/icons/RotateRight";
import PhotoSizeSelectSmallIcon from "@material-ui/icons/PhotoSizeSelectSmall";

class TurnPage extends React.Component {

    render() {
        return (
            <div className={styles.turnPage}>
                <h1>Psst!</h1>
                <h1><RotateRightIcon style={{fontSize: '100px'}}/></h1>
                <h2>Draai je device...</h2>
                <h1><PhotoSizeSelectSmallIcon style={{fontSize: '100px'}} /></h1>
                <h2>...of verander de verhouding van je scherm, zodat het breder is.</h2>
            </div>
        );
    };
};

export default TurnPage;