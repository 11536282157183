import json from "../initialState.js";

const initialState = json.objectives;

const objectives = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TIME_FOR_OBJECTIVE':
            const objectiveIndex = state.findIndex(objective => objective.id === action.payload.objectiveId);
            state[objectiveIndex] = Object.assign({}, state[objectiveIndex], { time: action.payload.time, isCorrect: action.payload.isCorrect });
            return [...state];
        case 'UNLOCK_OBJECTIVE':
            const i = state.findIndex(objective => objective.id === action.payload.objectiveId);
            state[i] = Object.assign({}, state[i], { locked: action.payload.locked, unlockTime: action.payload.unlockTime });
            return [...state];
        default:
            return state;
    }
};

export default objectives;