import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getCurrentPage, getScore} from "../../redux/selectors";

import Drawer from '@material-ui/core/Drawer';
import Tabs from '@material-ui/core/Tabs';
import Tab from "@material-ui/core/Tab";
import Box from '@material-ui/core/Box';
import {Backdrop} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'


import styles from './Footer.module.scss';

import Conclusion from "./Conclusion";
import Timeline from "./Timeline";
import Photos from "./Photos";

const TabContainer = (props) => (
    <div role="tabpanel" className={styles.tabContainer}>
        <Box>{props.children}</Box>
    </div>
);

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasBeenOpened: false,
            open: false,
            value: false
        };
    }

    toggleDrawer = event => {
        const newOpen = !this.state.open;
        this.setState({
            open: newOpen,
            value: newOpen ? 0 : false
        });
    };

    handleChange = (event, newValue) => {
        const {value, open} = this.state;

        if ((newValue === value || newValue > 2) && open) {
            this.toggleDrawer();
        } else {
            this.setState({
                hasBeenOpened: true,
                value: newValue,
                open: true
            });
        }
    };

    render() {
        const {currentPage} = this.props;
        const {value, open, hasBeenOpened} = this.state;

        const currentPageIsHouse = !open && (currentPage.id === 'house' || currentPage.id === 'attic' || currentPage.id === 'bedroom' || currentPage.id === 'living');
        const currentPageIsPeople = !open && (currentPage.id === 'bystanders' || currentPage.id === 'residents');

        return (
            <div className={styles.footer}>
                {!hasBeenOpened && currentPageIsPeople && (
                    <div className={styles.hintContainer}>
                    </div>
                )}
                <Drawer
                    anchor="bottom"
                    className={cn(styles.drawer, {
                        [styles.drawerOpen]: open,
                        [styles.drawerClose]: !open,
                    })}
                    classes={{
                        paper: cn(styles.drawer, {
                            [styles.drawerOpen]: open,
                            [styles.drawerClose]: !open,
                        }),
                    }}
                    onClose={this.toggleDrawer}
                    elevation={0}
                    variant="permanent"
                >
                    {this.props.score.conclusionMaterialSet !== 0 && this.props.score.conclusionTempSet !== 0 && (
                        <Link to={"score"}>
                            <button className={styles.completed_button}><h3>Lever je<br/>werk in<br/>KLIK HIER!</h3>
                            </button>
                        </Link>
                    )}
                    <Tabs
                        value={value}
                        onChange={this.handleChange}
                        classes={{
                            root: cn(styles.tabsRoot),
                            indicator: cn(styles.indicator),
                            flexContainer: cn(styles.tabsFlexContainer),
                            scroller: styles.tabsScoller
                        }}
                    >
                        <Tab
                            disableRipple
                            classes={{
                                root: cn(styles.tabRoot, styles.tab, {
                                    [styles.selectedTab]: currentPageIsPeople,
                                    [styles.tabSelected]: currentPageIsPeople
                                }),
                                selected: cn(styles.selectedTab, styles.tabSelected)
                            }}
                            label="Tijdlijn"
                        />
                        <Tab
                            disableRipple
                            classes={{
                                root: cn(styles.tabRoot, styles.tab, {
                                    [styles.selectedTab]: currentPageIsHouse,
                                    [styles.tabSelected]: currentPageIsHouse
                                }),
                                selected: cn(styles.selectedTab, styles.tabSelected)
                            }}
                            label="Foto's"
                        />
                        <Tab
                            disableRipple
                            classes={{
                                root: cn(styles.tabRoot, styles.tab),
                                selected: cn(styles.selectedTab, styles.tabSelected)
                            }}
                            label="Conclusie"
                        />
                        {open &&
                        <Tab
                            disableRipple
                            classes={{
                                root: cn(styles.tabRootClose, styles.tabClose),
                                selected: cn(styles.selectedTab, styles.tabSelected)
                            }}
                            icon={<CloseIcon fontSize={"large"}/>}
                        />}
                    </Tabs>
                    {value === false && <TabContainer>
                        <div></div>
                    </TabContainer>}
                    {value === 0 && <TabContainer><Timeline/></TabContainer>}
                    {value === 1 && <TabContainer><Photos/></TabContainer>}
                    {value === 2 && <TabContainer><Conclusion/></TabContainer>}
                </Drawer>
                <Backdrop open={open} onClick={this.toggleDrawer} timeout={0}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentPage: getCurrentPage(state),
        score: getScore(state)
    };
};

export default connect(mapStateToProps)(Footer);