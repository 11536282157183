import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";

import styles from './roomNavButtons.module.scss';
import Button from "../../../button";

class RoomNavButtons extends React.PureComponent {
  static propTypes = {
    leftBtnLabel: PropTypes.string.isRequired,
    leftBtnRoute: PropTypes.string.isRequired,
    rightBtnLabel: PropTypes.string.isRequired,
    rightBtnRoute: PropTypes.string.isRequired,
  };

  navigateTo = (location) => () => {
    this.props.history.push(location);
  };

  render() {
    const { leftBtnLabel, leftBtnRoute, rightBtnLabel, rightBtnRoute } = this.props;
    return (
      <>
        <div className={styles.buttonLeftContainer}>
          <Button
            onClick={this.navigateTo(leftBtnRoute)}
            classNames={styles.buttonLeft}
          >
            <>
              <span className={styles.label}>{leftBtnLabel}</span>
              <span className={styles.arrow}></span>
            </>
          </Button>
        </div>
        <div className={styles.buttonRightContainer}>
          <Button
            onClick={this.navigateTo(rightBtnRoute)}
            classNames={styles.buttonRight}
          >
            <>
              <span className={styles.arrow}></span>
              <span className={styles.label}>{rightBtnLabel}</span>
            </>
          </Button>
        </div>
      </>
    );
  };
}

export default withRouter(RoomNavButtons);