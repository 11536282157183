import React from 'react';
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setAudioAutoPlay, setCurrentPage} from "../../redux/actions";
import {getScore} from "../../redux/selectors";

import styles from './Score.module.scss';

import audioPlot from "../../assets/audio/recordings/Plot Floortje.mp3";
import audioEscapePlanCompetition from "../../assets/audio/recordings/vluchtplan_prijsvraag.mp3";
import audioEscapePlan from "../../assets/audio/recordings/vluchtplan.mp3";
import audioGood from "../../assets/audio/recordings/Afronding goed.mp3";
import audioMedium from "../../assets/audio/recordings/Afronding medium.mp3";
import audioBad from "../../assets/audio/recordings/Afronding slecht.mp3";

import TalkingCat from "./TalkingCat";
import Button from "../button";

class Score extends React.Component {
    constructor(props) {
        super(props);

        this.score = this.checkScore();

        switch (this.score) {
            case 'good':
                this.sources = [audioGood, audioPlot, audioEscapePlan, audioEscapePlanCompetition]
                break;
            case 'medium':
                this.sources = [audioMedium, audioPlot, audioEscapePlan, audioEscapePlanCompetition]
                break;
            default:
                this.sources = [audioBad, audioPlot, audioEscapePlan, audioEscapePlanCompetition]
        }

        const currentTime = new Date().getTime();
        this.expireTime = new Date(2019, 10, 30).getTime();

        if (currentTime > this.expireTime) {
            this.sources.pop()
        }

        this.state = {
            currentSrcIndex: 0,
            playing: true,
            buttonVisible: false
        };
        this.handleOnEnd = this.handleOnEnd.bind(this);
        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
    }

    handlePlay() {
        this.setState({
            playing: true
        });
    }

    handlePause() {
        this.setState({
            playing: false
        });
    }

    componentDidMount() {
        this.props.setCurrentPage("score");
    }


    handleOnEnd() {
        if (this.state.currentSrcIndex === (this.sources.length - 1)) {
            this.setState({
                ...this.state,
                playing: false,
                currentSrcIndex: 0
            });
            this.props.setAudioAutoPlay("score", false)
        } else {
            let nextIndex = this.state.currentSrcIndex;
            nextIndex++;
            this.setState({
                ...this.state,
                playing: true,
                currentSrcIndex: nextIndex
            });
        }
    }
    ;

    checkScore() {
        const correctTemp = this.props.score.conclusionTempIdSet === this.props.score.conclusionTempCorrect;
        const correctMaterial = this.props.score.conclusionMaterialIdSet === this.props.score.conclusionMaterialCorrect;

        if (correctTemp && correctMaterial) return 'good';
        if (((correctTemp || correctMaterial) && this.props.score.totalScore > 7)
          || ((!correctTemp || !correctMaterial) && this.props.score.totalScore > 15)) return 'medium';

        return 'bad';
    }

    navigateTo = (location) => () => {
        this.props.history.push(location);
    };

    render() {
        return (
            <div className={styles.score}>
                <div className={styles.btnContainer}>
                    <Button
                        size={"large"}
                        label="Maak je eigen vluchtplan"
                        onClick={this.navigateTo("/vluchtplan")}
                    />
                </div>
                <div className={styles.talkingCat}>
                    <TalkingCat
                        audioSource={this.sources[this.state.currentSrcIndex]}
                        playing={this.state.playing}
                        balloonColor={'blue'}
                        handleOnEnd={this.handleOnEnd}
                        handlePlay={this.handlePlay}
                        handlePause={this.handlePause}
                        catType={'triangle'}
                    />
                </div>
                <div className={styles.scoreText}>
                    {(() => {
                        switch (this.score) {
                            case 'good':
                                return <div className={styles.good}/>;
                            case 'medium':
                                return <div className={styles.medium}/>;
                            default:
                                return <div className={styles.bad}/>;
                        }
                    })()}
                </div>
                <div className={styles.footer}>
                    <div className={styles.tips}>
                        <h2>TIPS VOOR OPLADEN</h2>
                        <ul>
                            <li>Haal de stekker uit het stopcontact als het apparaat vol is</li>
                            <li>Niet laden in bed of op de bank</li>
                            <li>Niet laden tijdens het slapen</li>
                        </ul>
                    </div>
                </div>
            </div>

        );
    };
};

const mapStateToProps = (state) => {
    return {
        score: getScore(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    setAudioAutoPlay: (id, autoPlay) => dispatch(setAudioAutoPlay(id, autoPlay)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Score));