import React from 'react';

import styles from './Conclusion.module.scss';
import {connect} from "react-redux";
import {getAudioSettingsById, getScore, getUnlockedObjectivesByCategory} from "../../redux/selectors";
import {setAudioAutoPlay, setScoreItem} from "../../redux/actions";
import imgOxygen from "../../assets/objectives/OBJECTEN_DEF-19.jpg"
import imgTemperature from "../../assets/objectives/OBJECTEN_DEF-20.jpg"
import imgMaterial from "../../assets/objectives/OBJECTEN_DEF-21.jpg"
import audioInstructionsConclusion from "../../assets/audio/recordings/Uitleg branddriehoek.mp3";
import TalkingCat from "./TalkingCat";
import {ExternalControlledCarousel} from "./ExternalControlledCarousel";
import Triangle from "../../assets/interface/triangle.png";

class Conclusion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: this.props.audio.autoPlay,
            buttonVisible: false,
            hintVisible: true
        };

        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);
    }

    handlePlay() {
        this.setState({
            playing: true
        });
    }

    handlePause() {
        this.setState({
            playing: false
        });
    }

    handleOnEnd() {
        this.setState({
            playing: false,
            buttonVisible: true
        });
        this.props.setAudioAutoPlay("conclusion", false)
    }

    onChangeTemp = (index) => {
        if (this.state.hintVisible) this.setState({ hintVisible: false });
        this.props.setScoreItem('conclusionTempSet', index);
        if (index >= 1) {
            this.props.setScoreItem('conclusionTempIdSet', this.props.unlockedPhotos.filter(photo => photo.type === 'temperature')[index - 1].id);
        }
    };

    onChangeMaterial = (index) => {
        if (this.state.hintVisible) this.setState({ hintVisible: false });
        this.props.setScoreItem('conclusionMaterialSet', index);
        if (index >= 1) {
            this.props.setScoreItem('conclusionMaterialIdSet', this.props.unlockedPhotos.filter(photo => photo.type === 'material')[index - 1].id);
        }
    };

    unLockedItemsPerType = (type) => this.props.unlockedPhotos.filter(photo => photo.type === type);

    getCarouselNodes(unlockedItems, emptyPlaceholder) {
        let nodes = [];

        nodes.push(
          <div key="default">
              <img className={styles.thumb} src={emptyPlaceholder} alt="default"/>
          </div>
        );
        if (unlockedItems.length > 0) {
            const unlockedItemNodes = unlockedItems.map((photo, i) => (
              <div key={i + 1}>
                  <img className={styles.thumb} src={photo.image} alt={photo.name}/>
              </div>
            ));
            nodes = nodes.concat(unlockedItemNodes);
        }

        return nodes;
    }

    render() {
        const { hintVisible } = this.state;
        const unlockedMaterialItems = this.unLockedItemsPerType('material');
        const unlockedTemperatureItems = this.unLockedItemsPerType('temperature');
        const temperatureNodes = this.getCarouselNodes(unlockedTemperatureItems, imgTemperature);
        const materialNodes = this.getCarouselNodes(unlockedMaterialItems, imgMaterial);
        return (
            <div className={styles.conclusion}>
                <div className={styles.title}>
                    <h1>Branddriehoek</h1>
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.left}>
                        <div className={styles.talkingCat}>
                            <TalkingCat
                                audioSource={audioInstructionsConclusion}
                                playing={this.state.playing}
                                balloonColor={'blue'}
                                handleOnEnd={this.handleOnEnd}
                                handlePlay={this.handlePlay}
                                handlePause={this.handlePause}
                                catType={'triangle'}
                            />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.triangle}>
                            {hintVisible && (
                              <div className={styles.hintContainer}/>
                            )}
                            <img src={Triangle} alt="triangle" />
                            <div className={styles.flame}>
                                <div className={styles.oxygen}>
                                    <img className={styles.thumb} src={imgOxygen} alt="zuurstof"/>
                                    <div className={styles.oxygen_caption}><h3>Zuurstof</h3></div>
                                </div>
                                <div className={styles.material}>
                                    <ExternalControlledCarousel
                                        id="material"
                                        onChange={this.onChangeMaterial}
                                        selectedItem={this.props.score.conclusionMaterialSet}
                                    >
                                        {materialNodes}
                                    </ExternalControlledCarousel>
                                    <div className={styles.material_caption}><h3>Brandbaar materiaal</h3></div>
                                </div>
                                <div className={styles.temperature}>
                                    <ExternalControlledCarousel
                                        id="temperature"
                                        onChange={this.onChangeTemp}
                                        selectedItem={this.props.score.conclusionTempSet}
                                    >
                                        {temperatureNodes}
                                    </ExternalControlledCarousel>
                                    <div className={styles.temperature_caption}><h3>Temperatuur</h3></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unlockedPhotos: getUnlockedObjectivesByCategory(state, "photos"),
        score: getScore(state),
        audio: getAudioSettingsById(state, "conclusion")
    };
};

const mapDispatchToProps = (dispatch) => ({
    setScoreItem: (scoreId, value) => dispatch(setScoreItem(scoreId, value)),
    setAudioAutoPlay: (id, autoPlay) => dispatch(setAudioAutoPlay(id, autoPlay)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Conclusion);