import React from 'react';

import styles from './Photos.module.scss';

import PhotoObjectives from "./photoObjective/PhotoObjectives";
import Hint from "../hint/hint";
import {
    getCorrectObjectivesByCategory, getCurrentPage,
    getObjectivesByCategory,
    getUnlockedObjectivesByCategory
} from "../../redux/selectors";
import {connect} from "react-redux";

class Photos extends React.PureComponent {
    render() {
        const {currentPage, objectiveCount, unlockedObjectiveCount} = this.props;
        const currentPageIsHouse = (currentPage.id === 'house' || currentPage.id === 'attic' || currentPage.id === 'bedroom' || currentPage.id === 'living');
        return (
            <div className={styles.photos}>
                {unlockedObjectiveCount === 0 && (
                    <div className={styles.hintContainer}/>
                )}
                {unlockedObjectiveCount === 0 && !currentPageIsHouse && (
                    <div className={styles.hintContainerNavToHouse}/>
                )}
                {objectiveCount === unlockedObjectiveCount && (
                    <div className={styles.hintContainerSuccess}>
                        <Hint pointerLocation="topRight">
                            <h3>Super gedaan! <br/> Je hebt alle voorwerpen
                                gevonden. {this.props.timelineObjectives === this.props.correctTimelineObjectives && " Neem snel een kijkje bij het conclusie-werkblad."}
                            </h3>
                        </Hint>
                    </div>
                )}
                <div className={styles.photoObjectivesContainer}>
                    <PhotoObjectives category="photos"/>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        currentPage: getCurrentPage(state),
        objectiveCount: getObjectivesByCategory(state, 'photos').length,
        unlockedObjectiveCount: getUnlockedObjectivesByCategory(state, 'photos').length,
        timelineObjectives: getObjectivesByCategory(state, 'timeline').length,
        correctTimelineObjectives: getCorrectObjectivesByCategory(state, 'timeline').length,
    };
};
export default connect(mapStateToProps)(Photos);