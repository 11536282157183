import React from 'react';
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {getCurrentPage, getNextPage, getPreviousPage} from "../../redux/selectors";

import styles from './Header.module.scss';
import Button from "../button";

class Header extends React.Component {

    navigateTo = (location) => () => {
        this.props.history.push(location);
    };

    render() {
        return (
            <div className={styles.header}>
                <div className={styles.left}>
                    {this.props.prevPage && (
                        <Button
                            onClick={this.navigateTo(`./${this.props.prevPage.id}`)}
                            classNames={styles.leftButton}
                        >
                            <>
                                <span className={styles.arrow}></span>
                                <span className={styles.label}>{this.props.prevPage.name.toUpperCase()}</span>
                            </>
                        </Button>
                    )}
                </div>
                <div className={styles.center}>
                    <h1>{this.props.currentPage.name}</h1>
                    <h3>{this.props.currentPage.description}</h3>
                </div>
                <div className={styles.right}>
                    {this.props.nextPage && (
                        <Button
                            onClick={this.navigateTo(`./${this.props.nextPage.id}`)}
                            classNames={styles.rightButton}
                        >
                            <>
                                <span className={styles.label}>{this.props.nextPage.name.toUpperCase()}</span>
                                <span className={styles.arrow}></span>
                            </>
                        </Button>
                    )}
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        currentPage: getCurrentPage(state),
        nextPage: getNextPage(state, getCurrentPage(state).nextPage),
        prevPage: getPreviousPage(state, getCurrentPage(state).prevPage)
    };
};

export default connect(mapStateToProps)(withRouter(Header));