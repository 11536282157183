import React from 'react';
import styles from "./ObjectiveDialog.module.scss";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Slide from '@material-ui/core/Slide';
import ReactHowler from "react-howler";
import audioCamera from "../../../assets/audio/camera_shutter_001.mp3";
import audioSwoosh from "../../../assets/audio/pagerustle.mp3";
import Button from "../../button";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ObjectiveDialog extends React.Component {
    static propTypes = {
        objective: PropTypes.object.isRequired,
        open: PropTypes.bool,
        toggleDialog: PropTypes.func.isRequired,
        autoPlay: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.props,
            playing: this.props.autoPlay
        };

        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
    }

    toggleDialog = () => {
        this.props.toggleDialog();
    }

    handlePlay() {
        this.setState({
            ...this.state,
            playing: true
        });
    }

    handlePause() {
        this.setState({
            ...this.state,
            playing: false
        });
    }

    render() {
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.toggleDialog}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent', zIndex: 9999,
                    },
                }}
            >
                <ReactHowler
                    playing={this.state.playing}
                    src={this.props.open ? audioCamera : audioSwoosh}
                    volume={1}
                />
                <div>
                    <div className={styles.row}>
                        <div className={styles.photo}>
                            <img src={this.props.objective.image} alt={this.props.objective.name}/>
                            <h2>{this.props.objective.name}</h2>
                        </div>
                        <div className={styles.column}>
                            <div className={styles.description}>
                                <h3>{this.props.objective.description}</h3>
                            </div>
                            {this.props.objective.tip && (
                                <div className={styles.tips}>
                                    <h1>TIP</h1>
                                    <h3>{this.props.objective.tip}</h3>
                                </div>
                            )}
                            <div className={styles.buttonContainer}>
                                <Button label="Bewaar" onClick={this.toggleDialog} size="large"/>
                            </div>
                        </div>
                    </div>
                </div>

            </Dialog>
        );
    };
}

export default ObjectiveDialog;