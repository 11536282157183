import React, {Component} from 'react';
import cn from 'classnames';
import {Carousel} from "react-responsive-carousel";
import carouselStyles from "react-responsive-carousel/lib/styles/carousel.css";
import styles from './ExternalControlledCarousel.module.scss';

export class ExternalControlledCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentSlide: this.props.selectedItem
        };
    }

    next = () => {
        this.setState(state => ({
            currentSlide: state.currentSlide + 1
        }));
    }

    prev = () => {
        this.setState(state => ({
            currentSlide: state.currentSlide - 1
        }));
    }

    updateCurrentSlide = (index) => {
        const {currentSlide} = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index
            });
        }
        this.props.onChange(index);
    }

    render() {
        return (
            <div className={styles.containerStyle}>
                <button
                  onClick={this.props.children.length > 1 ? this.prev : undefined}
                  className={styles.buttonStyleLeft}
                >
                    <span className={styles.buttonStyleLeftBG}/>
                </button>
                <Carousel
                    className={cn(carouselStyles.carousel, styles.carousel)}
                    showArrows={false}
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    autoPlay={false}
                    width="100%"
                    dynamicHeight
                    infiniteLoop
                    swipeable={false}
                    selectedItem={this.state.currentSlide}
                    onChange={this.updateCurrentSlide}
                >
                    {this.props.children}
                </Carousel>
                <button
                  onClick={this.props.children.length > 1 ? this.next : undefined}
                  className={styles.buttonStyleRight}
                >
                    <span className={styles.buttonStyleRightBG}/>
                </button>
            </div>
        );
    }
}