import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import ReactGA from 'react-ga';

import styles from './EscapeRoute.module.scss';
import {getTitlePageSeen} from "../../redux/selectors";
import getEnvironment from "../../utilities/getEnvironment";
import imgExit from "../../assets/interface/nooduitganbordje_scheef-01.svg"
import Hint from "../hint/hint";

class EscapeRoute extends React.Component {

    navigateToContestPage = () => {
        ReactGA.pageview('/site/prijsvraag');
        window.open('/site/prijsvraag', '_blank');
    };

    render() {
        const {titlePageSeen} = this.props;


        const env = getEnvironment();
        if (!titlePageSeen && env !== 'development') {
            return <Redirect to="/"/>;
        }

        const currentTime = new Date().getTime();
        this.expireTime = new Date(2019, 10, 30).getTime();

        return (
            <div className={styles.escapeRoute}>
                <div className={styles.header}>
                    <h1>MAAK JE EIGEN VLUCHTPLAN <img src={imgExit} className={styles.emergency_exit} alt="emergency exit" /></h1>
                </div>
                <div className={styles.content}>
                    <h3><span className={styles.steps}>Stap 1: </span>Bedenk de snelste (en veiligste) route naar buiten. Omdat rook naar boven gaat kun je zelf het beste naar beneden vluchten.</h3>
                    <h3>&nbsp;</h3>
                    <h3><span className={styles.steps}>Stap 2: </span>Bedenk ook een tweede route (zoals bij Ward en Milan was de snelste route geblokkeerd).</h3>
                    <h3>&nbsp;</h3>
                    <h3><span className={styles.steps}>Stap 3: </span>Sluit steeds de deur achter je (zoals je net geleerd hebt, houdt dit de rook nog even tegen).</h3>
                    <h3>&nbsp;</h3>
                    <h3><span className={styles.steps}>Stap 4: </span>Spreek af met wie je vlucht en wie bijvoorbeeld de kat (zoals Floortje) meeneemt.</h3>
                    <h3>&nbsp;</h3>
                    <h3><span className={styles.steps}>Stap 5: </span>Spreek een vaste plek af voor de huissleutel(s).</h3>
                    <h3>&nbsp;</h3>
                    <h3><span className={styles.steps}>Stap 6: </span>Spreek af waar je elkaar buiten ziet.</h3>
                    <h3>&nbsp;</h3>
                    <h3><span className={styles.steps}>Stap 7: </span>Bel 112. (Natuurlijk alleen als er echt brand is!)</h3>
                    <h3>&nbsp;</h3>
                    <h3>&nbsp;</h3>
                    <h3>Nu ben je goed voorbereid voor als er bij jou thuis brand uitbreekt!</h3>
                </div>
                {currentTime <= this.expireTime && (
                    <div className={styles.hintContainer}>
                        <Hint pointerLocation="topLeft">
                            <h3>Win met jouw vluchtplan een toffe prijs voor de hele klas!</h3>
                            <h4>&nbsp;</h4>
                            <button onClick={this.navigateToContestPage} rel="noopener noreferrer" className={styles.buttonPlay}>
                                <span className={styles.hide}>Prijsvraag</span>
                            </button>
                            <h3>&nbsp;</h3>
                            <h3>&nbsp;</h3>
                        </Hint>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    titlePageSeen: getTitlePageSeen(state)
});

export default connect(mapStateToProps)(withRouter(EscapeRoute));