import React from "react";

import styles from "./PhotoObjectives.module.scss";
import {connect} from "react-redux";
import PhotoObjective from "./index";
import {getObjectivesByCategory} from "../../../redux/selectors";
import Grid from "@material-ui/core/Grid";

class PhotoObjectives extends React.Component {
  render() {
    return (
      <div className={styles.objectives}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          {this.props.objectives.map((objective, i) => (
            <Grid key={i} item>
              <PhotoObjective objective={objective}/>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    objectives: getObjectivesByCategory(state, ownProps.category)
  };
};

export default connect(mapStateToProps)(PhotoObjectives);
