import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {connect} from 'react-redux';

import styles from './Index.module.scss';

import Header from "./Header";
import Footer from "./Footer";
import Bystanders from "./Bystanders";
import House from "./house/House";
import Residents from "./Residents";
import Score from "./Score";
import {getCurrentPage, getLocation, getTitlePageSeen} from "../../redux/selectors";
import Attic from "./house/Attic";
import Bedroom from "./house/Bedroom";
import Living from "./house/Living";
import Intro from "./Intro";
import withTracker from "../tracking/withTracking";
import getEnvironment from "../../utilities/getEnvironment";

class Game extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 1);
  }

  render() {
    const { titlePageSeen, currentPage } = this.props;

    const env = getEnvironment();
    if (!titlePageSeen && env !== 'development') {
      return <Redirect to="/" />;
    }

    return (
      <div className={styles.game}>
        {currentPage && currentPage.header && <Header/>}
        <Route path="*/game/intro" component={withTracker(Intro)}/>
        <Route path="*/game/bystanders" component={withTracker(Bystanders)}/>
        <Route path="*/game/house" exact component={withTracker(House)}/>
        <Route path="*/game/house/attic" component={withTracker(Attic)}/>
        <Route path="*/game/house/bedroom" component={withTracker(Bedroom)}/>
        <Route path="*/game/house/living" component={withTracker(Living)}/>
        <Route path="*/game/residents" component={withTracker(Residents)}/>
        <Route path="*/score" component={withTracker(Score)}/>
        {currentPage && currentPage.footer && <Footer/>}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: getCurrentPage(state),
  location: getLocation(state),
  titlePageSeen: getTitlePageSeen(state)
});

export default connect(mapStateToProps)(Game);
