export const setLocation = location => ({
    type: "SET_LOCATION",
    payload: {
        location
    }
});

export const setCurrentPage = currentPage => ({
    type: "SET_CURRENT_PAGE",
    payload: currentPage
});


export const setTitlePageSeen = () => ({
    type: "SET_TITLE_PAGE_SEEN"
});

export const setAtticPageSeen = () => ({
    type: "SET_ATTIC_PAGE_SEEN"
});

export const setBedroomPageSeen = () => ({
    type: "SET_BEDROOM_PAGE_SEEN"
});

export const setLivingPageSeen = () => ({
    type: "SET_LIVING_PAGE_SEEN"
});

export const setTimeForObjective = (objective, time) => ({
    type: "SET_TIME_FOR_OBJECTIVE",
    payload: {
        objectiveId: objective.id,
        isCorrect: objective.realTime === time,
        time
    }
});

export const unlockObjective = (objectiveId, locked) => ({
    type: "UNLOCK_OBJECTIVE",
    payload: {
        objectiveId,
        locked,
        unlockTime: Date.now()
    }
});

export const addToScore = (scoreId, amount) => ({
    type: "ADD_TO_SCORE",
    payload: {
        scoreId,
        amount
    }
});

export const subFromScore = (scoreId, amount) => ({
    type: "SUB_FROM_SCORE",
    payload: {
        scoreId,
        amount
    }
});

export const setScoreItem = (scoreId, value) => ({
    type: "SET_SCORE_ITEM",
    payload: {
        scoreId,
        value
    }
});

export const setAudioAutoPlay = (id, autoPlay) => ({
    type: "SET_AUTO_PLAY",
    payload: {
        id,
        autoPlay
    }
});