import React from "react";
import PropTypes from "prop-types";

import styles from "./PhotoObjective.module.scss";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import ObjectiveDialog from "./ObjectiveDialog";

class PhotoObjective extends React.Component {
    static propTypes = {
        objective: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    toggleDialog = () => {
        if (this.props.objective.category !== "photos" || this.props.objective.locked === true) {
            return;
        }
        this.setState({...this.state, open: !this.state.open});
    };

    render() {
        const {objective} = this.props;
        const {open} = this.state;

        return (
            <span>
              <Card classes={{root: styles.photoObjective}} onClick={this.toggleDialog} square={true} elevation={2}>
                  {objective.locked ? (
                      <div className={styles.lockedObject}/>
                  ) : (
                      <CardMedia
                          classes={{root: styles.objectiveMedia}}
                          component="img"
                          src={objective.image}
                          title={objective.name}
                      />
                  )}
              </Card>
                {!objective.locked && (
                    <ObjectiveDialog
                      objective={objective}
                      open={open}
                      toggleDialog={this.toggleDialog}
                      autoPlay={false}
                    />
                )}
          </span>
        );
    }
}

export default PhotoObjective;
