import React from 'react';
import {connect} from "react-redux";
import RSC from 'react-scrollbars-custom';
import cn from 'classnames';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import {
    getCorrectObjectivesByCategory, getObjectivesByCategory,
    getObjectivesByCategoryOrderedByUnlockTime,
    getUnlockedObjectivesByCategory
} from "../../redux/selectors";
import {addToScore, setTimeForObjective, subFromScore} from "../../redux/actions";

import styles from './Timeline.module.scss';

import Box from "@material-ui/core/Box";
import TimelineObjective from "./timelineObjective";
import Hint from "../hint/hint";
import ReactHowler from "react-howler";
import successJingle from "../../assets/audio/success.mp3"

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const timeline = [
    {
        time: '20:25',
        items: []
    },
    {
        time: '20:30',
        items: []
    },
    {
        time: '22:30',
        items: []
    },
    {
        time: '23:30',
        items: []
    },
    {
        time: '00:48',
        items: []
    },
    {
        time: '00:50',
        items: [],
        small: true
    },
    {
        time: '00:52',
        items: [],
        small: true
    },
    {
        time: '00:59',
        items: [],
        small: true
    },
    {
        time: '01:00',
        items: [],
        small: true
    },
    {
        time: '01:04',
        items: []
    }
];

class Timeline extends React.Component {
    constructor(props) {
        super(props);

        this.lists = {};
        this.createLists();
        const objectivesPerTime = this.getObjectivesPerTime();
        this.state = {
            objectivesPerTime,
            ...this.lists,
            playing: false
        };

        this.handlePlay = this.handlePlay.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleOnEnd = this.handleOnEnd.bind(this);
    }

    componentDidUpdate(prevProps) {
        const prevObjectiveTimes = prevProps.objectives.map(objective => objective.time);
        const objectiveTimes = this.props.objectives.map(objective => objective.time);

        if (JSON.stringify(prevObjectiveTimes) !== JSON.stringify(objectiveTimes)) {
            this.createLists();
            this.setState({...this.lists});
            if (this.props.correctObjectives === this.props.objectives.length) {
                this.handlePlay();
            }
        }
    }

    handlePlay() {
        this.setState({
            playing: true
        });
    }

    handlePause() {
        this.setState({
            playing: false
        });
    }

    handleOnEnd() {
        this.setState({
            playing: false
        });
    }

    createLists() {
        this.lists.source = this.props.objectives.filter(objective => !objective.time);
        timeline.forEach(time => {
            this.lists[time.time] = this.props.objectives.filter(objective => objective.time === time.time);
        });
    }

    getObjectivesPerTime() {
        return this.props.objectives.reduce((itemsPerTime, objective) => {
            if (!itemsPerTime.hasOwnProperty(objective.realTime)) {
                itemsPerTime[objective.realTime] = 0;
            }
            itemsPerTime[objective.realTime]++;
            return itemsPerTime;
        }, {});
    }

    getList = id => this.state[id];

    onDragEnd = result => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sourceList = this.getList(source.droppableId);
        const item = sourceList[source.index];
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                sourceList,
                source.index,
                destination.index
            );

            this.setState({
                [source.droppableId]: items
            });
        } else {
            const time = destination.droppableId !== 'source' ? destination.droppableId : null;
            this.props.setTimeForObjective(item, time);
        }
    };

    onDragStart = () => {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    };

    renderEmptyBlocks(count) {
        const blocks = [];
        for (let i = 0; i < count; i++) {
            blocks.push(
                <div key={i} className={styles.emptyBlock}/>
            )
        }
        return blocks;
    }

    render() {
        return (
            <div className={styles.timelineContainer}>
                <ReactHowler
                    src={successJingle}
                    playing={this.state.playing}
                    volume={1}
                    onEnd={this.handleOnEnd}
                />
                <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                    <div className={styles.timelineSection}>
                        <h3>{this.props.unlockedObjectives} van de {this.props.objectives.length} ontgrendeld
                            en {this.props.correctObjectives} staan op de juiste plek</h3>
                        <RSC
                            style={{width: '100%', height: '100%'}}
                            trackXProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef} className={styles.timelineScrollX}/>;
                                }
                            }}
                            scrollerProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef} className={styles.timelineScroller}/>;
                                }
                            }}
                            contentProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef}
                                                 className={styles.timelineScrollerContent}/>;
                                }
                            }}
                        >
                            <div className={styles.timeline}>
                                <div className={styles.timelineContent}>
                                    {timeline.map((time, i) => {
                                        const spotsForColumn = this.state.objectivesPerTime[time.time];
                                        const openSpotsForColumn = spotsForColumn - this.state[time.time].length;
                                        const timeColClassNames = cn(styles.timeCol, {
                                            [styles.small]: time.small,
                                            [styles.hasItem]: this.state[time.time].length
                                        });
                                        return (
                                            <div key={i} className={timeColClassNames}>
                                                <div className={styles.title}>
                                                    <h3>{time.time}</h3>
                                                </div>
                                                <Droppable
                                                    droppableId={time.time}
                                                    isDropDisabled={openSpotsForColumn === 0}
                                                >
                                                    {(provided, snapshot) => {
                                                        const columnClassNames = cn(styles.objectiveList, {
                                                            [styles.dragging]: snapshot.isDraggingOver
                                                        });
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                className={columnClassNames}
                                                            >
                                                                {this.state[time.time].map((item, i) => (
                                                                    <Draggable
                                                                        key={item.id}
                                                                        draggableId={item.id}
                                                                        isDragDisabled={item.fixed}
                                                                        index={i}
                                                                    >
                                                                        {(provided, snapshot) => (
                                                                            <div
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                className={cn(styles.item, {
                                                                                    [styles.isDragging]: snapshot.isDragging,
                                                                                    [styles.isLocked]: item.locked,
                                                                                    [styles.isFixed]: item.fixed
                                                                                })}
                                                                            >
                                                                                <TimelineObjective objective={item}/>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                ))}
                                                                <div className={styles.emptyBlocks}>
                                                                    {this.renderEmptyBlocks(spotsForColumn)}
                                                                </div>
                                                                {provided.placeholder}
                                                            </div>
                                                        );
                                                    }}
                                                </Droppable>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </RSC>
                    </div>
                    <div className={styles.sourceContainer}>
                        {this.state.source.length > 0 && this.state.source.filter(x => x.locked === false).length === 0 ? (
                            <div className={styles.hintContainerLocked}/>
                        ) : (this.props.unlockedObjectives > 3 && this.props.correctObjectives <= 3 &&
                            <div className={styles.hintContainerUnlocked}/>)
                        }
                        {this.props.correctObjectives === this.props.objectives.length && (
                            <div className={styles.hintContainerSuccess}>
                                <Hint pointerLocation="topRight">
                                    <h3>Super gedaan! <br/> De tijdlijn is helemaal
                                        goed.{this.props.unlockedPhotos === this.props.totalPhotos && " Neem snel een kijkje bij het conclusie-werkblad."}
                                    </h3>
                                </Hint>
                            </div>
                        )}
                        <RSC
                            style={{width: '100%', height: '100%', minHeight: '12rem', backgroundColor: 'white'}}
                            wrapperProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef} className={styles.sourceWrapper}/>;
                                }
                            }}
                            scrollerProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef} className={styles.sourceScroller}/>;
                                }
                            }}
                            contentProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef}
                                                 className={styles.sourceScrollerContent}/>;
                                }
                            }}
                            trackXProps={{
                                renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} ref={elementRef} className={styles.sourceScrollX}/>;
                                }
                            }}
                        >
                            <Droppable droppableId="source" direction="horizontal">
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        classes={{root: styles.sourceList}}
                                        boxShadow={2}
                                    >
                                        {this.state.source.map((item, i) => (
                                            <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                isDragDisabled={item.locked}
                                                index={i}
                                            >
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={cn(styles.item, {
                                                            [styles.isDragging]: snapshot.isDragging,
                                                            [styles.isLocked]: item.locked
                                                        })}
                                                    >
                                                        <TimelineObjective objective={item}/>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </Box>
                                )}
                            </Droppable>
                        </RSC>
                    </div>
                </DragDropContext>
            </div>
        );
    };
}

const mapStateToProps = (state) => ({
    objectives: getObjectivesByCategoryOrderedByUnlockTime(state, 'timeline'),
    unlockedObjectives: getUnlockedObjectivesByCategory(state, 'timeline').length,
    correctObjectives: getCorrectObjectivesByCategory(state, 'timeline').length,
    unlockedPhotos: getUnlockedObjectivesByCategory(state, 'photos').length,
    totalPhotos: getObjectivesByCategory(state, 'photos').length,
});

const mapDispatchToProps = (dispatch) => ({
    setTimeForObjective: (objective, time) => {
        dispatch(setTimeForObjective(objective, time));
        if (objective.realTime === time) {
            // correct => add score
            dispatch(addToScore('totalScore', 1))
        } else if (objective.isCorrect) {
            // objective was in correct position, but moved to new incorrect position => sub score
            dispatch(subFromScore('totalScore', 1))
        }
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
