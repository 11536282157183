import pubsubCreator from 'pubsubjs';

// make this pubsub a Singleton for audio
export default (() => {
  let instance;

  function createPubSubInstance() {
    return pubsubCreator.create();
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createPubSubInstance();
      }

      return instance;
    }
  };
})();
