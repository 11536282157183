import audioJack from '../assets/audio/recordings/Jack Wieringa - Brandweer.mp3'
import audioSara from '../assets/audio/recordings/Sarah van Dam_v01.mp3'
import audioYounes from '../assets/audio/recordings/Younes Dogan_v01.mp3'
import audioWard from '../assets/audio/recordings/Ward Kuiper.mp3'
import audioMilan from '../assets/audio/recordings/Milan Kuiper.mp3'
import audioFloortje from '../assets/audio/recordings/Floortje_v04.mp3'

import avatarJack from '../assets/characters/Brandweerman_JACK_WIERINGA_met_rand.jpg'
import avatarSara from '../assets/characters/Buurvrouw_SARA_VAN_DAM_met_rand.jpg'
import avatarYounes from '../assets/characters/Voorbijganger_YOUNES_DOGAN_met_rand.jpg'
import avatarWard from '../assets/characters/Vader_WARD_KUIPERS_met_rand.jpg'
import avatarMilan from '../assets/characters/Zoon_MILAN_KUIPERS_met_rand.jpg'
import avatarFloortje from '../assets/characters/Poes_FLOORTJE_met_rand.jpg'

import woonkamer_telefoon from '../assets/objectives/OBJECTEN_DEF-01.jpg'
import woonkamer_stoel from '../assets/objectives/OBJECTEN_DEF-02.jpg'
import woonkamer_rookmelders from '../assets/objectives/OBJECTEN_DEF-03.jpg'
import woonkamer_laptop from '../assets/objectives/OBJECTEN_DEF-04.jpg'
import woonkamer_deur from '../assets/objectives/OBJECTEN_DEF-05.jpg'
import woonkamer_kaars from '../assets/objectives/OBJECTEN_DEF-06.jpg'
import slaapkamer_hoverboard from '../assets/objectives/OBJECTEN_DEF-07.jpg'
import slaapkamer_lamp from '../assets/objectives/OBJECTEN_DEF-08.jpg'
import slaapkamer_tablet from '../assets/objectives/OBJECTEN_DEF-09.jpg'
import zolder_stekkerblokken from '../assets/objectives/OBJECTEN_DEF-12.jpg'
import zolder_speaker from '../assets/objectives/OBJECTEN_DEF-13.jpg'
import zolder_console from '../assets/objectives/OBJECTEN_DEF-14.jpg'
import zolder_droger from '../assets/objectives/OBJECTEN_DEF-15.jpg'
import woonkamer_tafel from '../assets/objectives/OBJECTEN_DEF-17.jpg'
import slaapkamer_gordijnen from '../assets/objectives/OBJECTEN_DEF-18.jpg'
//import slaapkamer_gitaar from '../components/assets/objectives/OBJECTEN_DEF-10.jpg'
//import slaapkamer_versterker from '../components/assets/objectives/OBJECTEN_DEF-11.jpg'
//import slaapkamer_ladenkastje from '../components/assets/objectives/OBJECTEN_DEF-16.jpg'

export default {
    settings: {
        location: "school",
        audio: [
            {
                id: "intro",
                autoPlay: true,

            },
            {
                id: "house",
                autoPlay: true,

            }, {
                id: "score",
                autoPlay: true,

            }, {
                id: "conclusion",
                autoPlay: true,

            }
        ]
    },
    residents: [
        {
            id: "milan",
            name: "Milan Kuipers",
            description: "Zoon",
            image: avatarMilan,
            audioFile: audioMilan,
            linkedObjectives: []
        },
        {
            id: "ward",
            name: "Ward Kuipers",
            description: "Vader",
            image: avatarWard,
            audioFile: audioWard,
            linkedObjectives: [
                "ward_001",
                "ward_002"
            ]
        },
        {
            id: "floortje",
            name: "Floortje",
            description: "Poes",
            image: avatarFloortje,
            audioFile: audioFloortje,
            linkedObjectives: [
                "floortje_001",
                "floortje_002",
                "floortje_003"
            ]
        }
    ],
    bystanders: [
        {
            id: "jack",
            name: "Jack Wieringa",
            description: "Brandweerman",
            image: avatarJack,
            audioFile: audioJack,
            linkedObjectives: [
                "jack_001",
                "jack_002"
            ]
        },
        {
            id: "sara",
            name: "Sara van Dam",
            description: "Buurvrouw",
            image: avatarSara,
            audioFile: audioSara,
            linkedObjectives: [
                "sara_001"
            ]
        },
        {
            id: "younes",
            name: "Younes Dogan",
            description: "Voorbijganger",
            image: avatarYounes,
            audioFile: audioYounes,
            linkedObjectives: [
                "younes_001"
            ]
        }
    ],
    pages: [
        {
            id: "intro",
            name: "Intro",
            description: "",
            header: false,
            footer: false,
            nextPage: "bystanders",
            prevPage: null
        },
        {
            id: "bystanders",
            name: "Hulpverleners",
            description: "Wat hebben de hulpverleners gedaan?",
            header: true,
            footer: true,
            nextPage: "residents",
            prevPage: null
        },
        {
            id: "house",
            name: "Het huis",
            description: "De situatie gisteravond om 23:00",
            header: true,
            footer: true,
            nextPage: null,
            prevPage: "residents"
        },
        {
            id: "residents",
            name: "Bewoners",
            description: "Wat hebben de bewoners gedaan?",
            header: true,
            footer: true,
            nextPage: "house",
            prevPage: "bystanders"
        },
        {
            id: "score",
            name: "Score",
            header: false,
            footer: false,
            nextPage: "",
            prevPage: ""
        },
        {
            id: "attic",
            name: "Zolder",
            description: "",
            header: false,
            footer: true,
            nextPage: null,
            prevPage: null
        },
        {
            id: "bedroom",
            name: "Slaapkamer",
            description: "",
            header: false,
            footer: true,
            nextPage: null,
            prevPage: null
        },
        {
            id: "living",
            name: "Huiskamer",
            description: "",
            header: false,
            footer: true,
            nextPage: null,
            prevPage: null
        },
    ],
    objectives: [
        {
            id: "milan_001",
            name: "Telefoon aan oplader",
            description: "Telefoon aan oplader",
            category: "timeline",
            realTime: '20:25',
            time: '20:25',
            person: 'milan',
            fixed: true,
            locked: false,
            isCorrect: true
        },
        {
            id: "milan_002",
            name: "Milan naar bed",
            description: "Milan naar bed",
            category: "timeline",
            realTime: '20:30',
            time: '20:30',
            person: 'milan',
            fixed: true,
            locked: false,
            isCorrect: true
        },
        {
            id: "ward_001",
            name: "Ward naar bed",
            description: "Ward naar bed",
            category: "timeline",
            realTime: '22:30',
            person: 'ward',
            locked: true,
            isCorrect: false
        },
        {
            id: "floortje_001",
            name: "Floortje naar zolder",
            description: "Floortje naar zolder",
            category: "timeline",
            realTime: '23:30',
            person: 'floortje',
            locked: true,
            isCorrect: false
        },
        {
            id: "brand",
            name: "Brand in woonkamer",
            description: "Brand in woonkamer",
            category: "timeline",
            realTime: '00:48',
            time: '00:48',
            fixed: true,
            person: null,
            locked: false,
            isCorrect: true
        },
        {
            id: "ward_002",
            name: "Ward schrikt wakker",
            description: "Ward schrikt wakker",
            category: "timeline",
            realTime: '00:50',
            person: 'ward',
            locked: true,
            isCorrect: false
        },
        {
            id: "younes_001",
            name: "Ruit kapot",
            description: "Ruit kapot",
            category: "timeline",
            realTime: '00:52',
            person: 'younes',
            locked: true,
            isCorrect: false
        },
        {
            id: "jack_001",
            name: "Brandweer op locatie",
            description: "Brandweer op locatie",
            category: "timeline",
            realTime: '00:59',
            person: 'jack',
            locked: true,
            isCorrect: false
        },
        {
            id: "sara_001",
            name: "Milan en Ward gered",
            description: "Milan en Ward gered",
            category: "timeline",
            realTime: '01:00',
            person: 'sara',
            locked: true,
            isCorrect: false
        },
        {
            id: "jack_002",
            name: "Brand geblust",
            description: "Brand geblust",
            category: "timeline",
            realTime: '01:04',
            person: 'jack',
            locked: true,
            isCorrect: false
        },
        {
            id: "slaapkamer_hoverboard",
            name: "Hoverboard",
            description: "Milans hoverboard staat in zijn kamer, gelukkig zit de stekker niet in het stopcontact!",
            tip: "Alleen opladen als je erbij bent en de stekker eruit na opladen.",
            category: "photos",
            type: "temperature",
            image: slaapkamer_hoverboard,
            locked: true
        },
        {
            id: "slaapkamer_lamp",
            name: "Lamp bij gordijn",
            description: "Een handig leeslampje, maar staat hij niet een beetje dicht bij het gordijn?",
            tip: "Zorg dat hete lampen niet tegen een gordijn staan.",
            category: "photos",
            type: "temperature",
            image: slaapkamer_lamp,
            locked: true
        },
        {
            id: "slaapkamer_tablet",
            name: "Kapot snoertje",
            description: "Het snoertje van de oplader is kapot, maar Milan had geen zin om een nieuwe te kopen.",
            tip: "Vervang kapotte snoertjes, deze kunnen namelijk brand veroorzaken.",
            category: "photos",
            type: "temperature",
            image: slaapkamer_tablet,
            locked: true
        },
        // {
        //     id: "slaapkamer_ladenkastje",
        //     name: "Ladenkastje",
        //     description: "Kastje waarin Milan al zijn geheimen kan bewaren. En zijn sokken natuurlijk.",
        //     tip: "",
        //     category: "photos", type: "material",
        //     image: slaapkamer_ladenkastje,
        //     locked: true
        // },
        {
            id: "slaapkamer_gordijnen",
            name: "Gordijnen",
            description: "Stoffen gordijnen in Milans lievelingskleuren.",
            tip: "",
            category: "photos",
            type: "material",
            image: slaapkamer_gordijnen,
            locked: true
        },
        // {
        //     id: "slaapkamer_gitaar",
        //     name: "Gitaar",
        //     description: "Milan z'n dierbaarste bezit is zijn elektrische gitaar. Wat is jouw dierbaarste bezit?",
        //     tip: "",
        //     category: "photos",
        //     type: "material",
        //     image: slaapkamer_gitaar,
        //     locked: true
        // },
        // {
        //     id: "slaapkamer_versterker",
        //     name: "Klem zittende kabel",
        //     description: "Met deze versterker kunnen ook de buren van Milan's gitaarspel mee genieten.",
        //     tip: "Zorg dat alle snoertjes niet klem liggen.",
        //     category: "photos",
        //     type: "temperature",
        //     image: slaapkamer_versterker,
        //     locked: true
        // },
        {
            id: "woonkamer_deur",
            name: "Deur op een kier",
            description: "De deur staat op een kier, daardoor kan giftige rook zich gemakkelijk verspreiden.",
            tip: "Sluit alle binnendeuren voor je gaat slapen.",
            category: "photos",
            type: "misc",
            image: woonkamer_deur,
            locked: true
        },
        {
            id: "woonkamer_laptop",
            name: "Opgeladen laptop",
            description: "Wards laptop is helemaal opgeladen, hij heeft de stekker uit het stopcontact gehaald.",
            tip: "Haal de stekker eruit als de batterij vol is.",
            category: "photos",
            type: "temperature",
            image: woonkamer_laptop,
            locked: true
        },
        {
            id: "woonkamer_stoel",
            name: "Chille stoel",
            description: "Floortjes favoriete plekje. Hier kan je trouwens ook lekker TV kijken.",
            tip: "Leg apparaten niet op een stoel, bank of bed tijdens het opladen.",
            category: "photos",
            type: "material",
            image: woonkamer_stoel,
            locked: true
        },
        {
            id: "woonkamer_telefoon",
            name: "Telefoon in oplader",
            description: "Milans telefoon is helemaal opgeladen, maar zit nog steeds in het stopcontact.",
            tip: "Laad je telefoon overdag op, wel zo veilig!",
            category: "photos",
            type: "temperature",
            image: woonkamer_telefoon,
            locked: true
        },
        {
            id: "woonkamer_kaars",
            name: "Kaars",
            description: "Had Ward de kaars nou uitgeblazen voordat hij naar bed ging?",
            tip: "Zorg dat huisdieren een brandende kaars niet kunnen omgooien.",
            category: "photos",
            type: "temperature",
            image: woonkamer_kaars,
            locked: true
        }, {
            id: "woonkamer_rookmelders",
            name: "Losse rookmelders",
            description: "Vorige week heeft Ward rookmelders gekocht... het ophangen is nog niet gelukt.",
            tip: "Vraag je ouders eens of jullie rookmelders werken?",
            category: "photos",
            type: "misc",
            image: woonkamer_rookmelders,
            locked: true
        },
        {
            id: "woonkamer_tafel",
            name: "Salontafel",
            description: "Een houten salontafel.",
            tip: "",
            category: "photos",
            type: "material",
            image: woonkamer_tafel,
            locked: true
        },
        {
            id: "zolder_stekkerblokken",
            name: "Stekkerdozen warboel ",
            description: "Ward en Milan hebben een warboel aan stekkerdozen om alle apparatuur aan te kunnen sluiten.",
            tip: "Verbind geen stekkerdoos met een andere stekkerdoos.",
            category: "photos",
            type: "temperature",
            image: zolder_stekkerblokken,
            locked: true
        },
        {
            id: "zolder_speaker",
            name: "Boxje met gek snoertje",
            description: "Yes! Oplader gevonden die ook past, al wordt die wel heel warm tijdens het opladen.",
            tip: "Markeer snoertjes, zodat je weet voor welk apparaat ze zijn.",
            category: "photos",
            type: "temperature",
            image: zolder_speaker,
            locked: true
        },
        {
            id: "zolder_console",
            name: "Ingebouwde console",
            description: "Rondom de console is het lekker volgepropt met spullen. Het is hier trouwens ook best stoffig!",
            tip: "Zorg voor voldoende ruimte zodat de warmte van de console weg kan",
            category: "photos",
            type: "temperature",
            image: zolder_console,
            locked: true
        },
        {
            id: "zolder_droger",
            name: "Droger met vol filter",
            description: "Er zit veel stof in de filter van de droger. Gevaarlijk, want daardoor kan brand ontstaan.",
            tip: "Leeg de filter en zet drogers niet aan als je slaapt of weg bent.",
            category: "photos",
            type: "material",
            image: zolder_droger,
            locked: true
        },
    ],
    score: {
        conclusionTempSet: 0,
        conclusionMaterialSet: 0,
        conclusionTempIdSet: '',
        conclusionMaterialIdSet: '',
        conclusionTempCorrect: 'woonkamer_telefoon',
        conclusionMaterialCorrect: 'woonkamer_stoel',
        totalScore: 0
    },
}
