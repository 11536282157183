import json from "../initialState.js";

const initialState = {
    currentPage: "intro",
    titlePageSeen: false,
    atticPageSeen: false,
    bedroomPageSeen: false,
    livingPageSeen: false,
    availablePages: json.pages
};

const page = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PAGE':
            return {...state, currentPage: action.payload};
        case 'SET_ATTIC_PAGE_SEEN':
            return {...state, atticPageSeen: true};
        case 'SET_BEDROOM_PAGE_SEEN':
            return {...state, bedroomPageSeen: true};
        case 'SET_LIVING_PAGE_SEEN':
            return {...state, livingPageSeen: true};
        case 'SET_TITLE_PAGE_SEEN':
            return {...state, titlePageSeen: true};
        default:
            return state;
    }
};

export default page;
