import React from 'react';
import {connect} from "react-redux";
import cn from 'classnames';

import styles from './Attic.module.scss';
import ImageMapper from "react-image-mapper";

import {addToScore, setAtticPageSeen, setCurrentPage, unlockObjective} from "../../../redux/actions";
import {
    getCurrentPage,
    getObjectivesByCategory,
    getScore,
    getUnlockedObjectivesByCategory
} from "../../../redux/selectors";
import ObjectiveDialog from "../photoObjective/ObjectiveDialog";
import full from "../../../assets/rooms/attic_textured_72dpi.jpg";
import Hint from "../../hint/hint";
import HouseHeader from "./houseHeader";
import RoomNavButtons from "./roomNavButtons/roomNavButtons";
import RoomPhotoTips from "./roomPhotoTips/roomPhotoTips";

const mapFull = {
    name: "back",
    areas: [{
        name: "zolder_console",
        shape: "poly",
        coords: [605, 646, 890, 631, 885, 687, 606, 700],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)",
    }, {
        name: "zolder_droger",
        shape: "poly",
        coords: [1778, 422, 1873, 439, 1875, 619, 1795, 656, 1683, 639, 1690, 485, 1704, 449],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "zolder_stekkerblokken",
        shape: "circle",
        coords: [533, 737, 70],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }, {
        name: "zolder_speaker",
        shape: "circle",
        coords: [501, 867, 76],
        prefillColor: "rgba(255, 255, 255, 0)",
        fillColor: "rgba(255, 255, 255, 0.1)"
    }]
}

class Attic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this.props,
            screenWidth: 0,
            screenHeight: 0,
            hoveredArea: null,
            openDialog: false,
            objective: null,
            flash: false,
            hidePhotoTips: true
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.timerID = setInterval(
            () => this.togglePhotoTips(),
            120000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    togglePhotoTips() {
        this.setState({hidePhotoTips: !this.state.hidePhotoTips})
    }

    componentWillMount() {
        this.props.setCurrentPage("attic");
        this.props.setAtticPageSeen();
        window.removeEventListener('resize', this.updateWindowDimensions);
    };

    updateWindowDimensions() {
        this.setState({...this.state, screenWidth: window.innerWidth, screenHeight: window.innerHeight});
    }

    enterArea = (area) => {
        this.setState({
            ...this.state,
            hoveredArea: area,
        });
    }

    leaveArea = (area) => {
        this.setState({
            ...this.state,
            hoveredArea: null,
        });
    }

    handleOnClick = (area) => {
        this.setState({
            ...this.state,
            objective: this.props.objectives.find(o => o.id === area.name),
            hidePhotoTips: true
        })
        this.props.unlockObjective(area.name, false);
        this.props.addToScore("totalScore", 1);
        this.toggleFlash();
        this.toggleDialog();
    }

    toggleDialog = () => {
        this.toggleFlash();
        this.setState({openDialog: !this.state.openDialog});
    };

    toggleFlash = () => {
        this.setState({flash: !this.state.flash});
    };

    render() {
        const totalPhotoCount = this.props.objectives.length;
        const totalUnlockedPhotoCount = this.props.unlockedObjectives.length;
        const roomObjectsFound = this.props.unlockedObjectives.filter(x => x.id.includes('zolder')).length;
        const totalRoomObjects = this.props.objectives.filter(x => x.id.includes('zolder')).length;
        return (
            <div className={styles.attic}>
                <HouseHeader
                    closeBtn={!this.state.openDialog}
                    title={this.props.currentPage.name}
                    description={`${roomObjectsFound} van de ${totalRoomObjects} objecten gevonden`}
                />
                <div className={cn(styles.flash, this.state.flash && styles.active)}/>
                <div className={styles.imageMapContainer}>
                    <ImageMapper
                        src={full}
                        map={mapFull}
                        width={this.state.screenWidth - 40}
                        imgWidth={1920}
                        lineWidth={0}
                        strokeColor="rgba(255, 255, 255, 0)"
                        onClick={area => this.handleOnClick(area)}
                        onMouseEnter={area => this.enterArea(area)}
                        onMouseLeave={area => this.leaveArea(area)}
                    />
                    <RoomPhotoTips map={mapFull} room='zolder' screenWidth={this.state.screenWidth}
                                   hidden={this.state.hidePhotoTips}/>
                </div>
                {roomObjectsFound === 0 && (
                    <div className={styles.hintContainer}>
                        <Hint pointerLocation="topRight">
                            <h3>Zoek en klik op de juiste voorwerpen om een foto te maken</h3>
                        </Hint>
                    </div>
                )}
                {roomObjectsFound === totalRoomObjects && totalPhotoCount !== totalUnlockedPhotoCount &&
                <div className={styles.hintContainerRight}/>}
                {totalPhotoCount === totalUnlockedPhotoCount &&
                <div className={styles.hintContainerConclusion}/>}
                {this.state.objective && (
                    <ObjectiveDialog
                        objective={this.state.objective}
                        open={this.state.openDialog}
                        toggleDialog={this.toggleDialog}
                        autoPlay={true}
                    />
                )}
                <RoomNavButtons
                    leftBtnLabel="Slaapkamer"
                    leftBtnRoute="../house/bedroom"
                    rightBtnLabel="Woonkamer"
                    rightBtnRoute="../house/living"
                />
            </div>
        );
    }
    ;
}

const mapStateToProps = (state) => {
    return {
        currentPage: getCurrentPage(state),
        objectives: getObjectivesByCategory(state, 'photos'),
        unlockedObjectives: getUnlockedObjectivesByCategory(state, 'photos'),
        score: getScore(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    unlockObjective: (objectiveId, locked) => dispatch(unlockObjective(objectiveId, locked)),
    addToScore: (scoreId, amount) => dispatch(addToScore(scoreId, amount)),
    setAtticPageSeen: () => dispatch(setAtticPageSeen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attic);